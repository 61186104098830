exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accepting-wallet-js": () => import("./../../../src/pages/accepting-wallet.js" /* webpackChunkName: "component---src-pages-accepting-wallet-js" */),
  "component---src-pages-by-email-js": () => import("./../../../src/pages/by-email.js" /* webpackChunkName: "component---src-pages-by-email-js" */),
  "component---src-pages-in-person-js": () => import("./../../../src/pages/in-person.js" /* webpackChunkName: "component---src-pages-in-person-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-on-website-js": () => import("./../../../src/pages/on-website.js" /* webpackChunkName: "component---src-pages-on-website-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

